/* hero.css */

.crossword-square {
  width: 2.5rem;
  height: 2.5rem;
  border: 2px solid white;
  margin: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.crossword-square.blank {
  border: none;
}

.crossword-letter {
  font-size: 1.5rem;
  font-weight: bold;
}

/* Speech bubble styling */
.speech-bubble {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  color: #000;
}

.speech-bubble::after {
  content: '';
  position: absolute;
  bottom: -20px; /* Adjust as needed */
  left: 40px; /* Adjust as needed */
  border-width: 10px 10px 0;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.9) transparent;
  display: block;
  width: 0;
}



