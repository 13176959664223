.subjects-banner-wrapper {
    width: 100%;
    overflow: hidden;
  }
  
  .subjects-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .subjects-track {
    display: flex;
    animation: scroll 90s linear infinite;
  }
  
  .subject-item {
    flex-shrink: 0;
    padding: 0 1rem;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-33.33%); /* Adjust to ensure it scrolls just the right distance */
    }
  }
  